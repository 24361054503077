@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-VariableFont_wght.ttf') format('truetype');
  }


body {
    background-image: linear-gradient(to right bottom, #2895d8, #4398db, #569cde, #679fe1, #75a3e4, #71aceb, #6db6f2, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    font-family: Rubik;
}

p {
    font-size: 22px;
}

.promo {
    border: 1px solid #2895D8;
    border-radius: 20px;
    margin: 0 auto;
    cursor: pointer;
    width: fit-content;
    padding: 2px 10px;
}